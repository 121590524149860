<template>
  <div class="section-empty text-secondary-heading-2">
    <slot />
  </div>
</template>

<style scoped>
  .section-empty {
    display: flex;
    padding: 80px 10px;
    justify-content: center;
    align-items: center;
  }
</style>
